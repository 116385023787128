.plat-navbar {
    background-color: #fff !important;
    padding: 0px 35px !important;
    box-shadow: none !important;
    border-bottom: 0.37px solid #848484;
}
.menu-top-img {
    border-radius: 2px;
}
.plat-navbar-top-menu {
    padding: 0px 0px 0px 2px !important;
}
.dropdown-menu {
    right: 0;
    left: auto;
}
.menu-topicon-username {
    color: #000;
    padding-left: 13px !important;
}
.dropdown-menu {
color: #4f4f4f;
margin: 0;
padding-top: 0;
padding-bottom: 0;
border: 0;
box-shadow: 0 2px 15px -3px rgb(0 0 0 / 7%), 0 10px 20px -2px rgb(0 0 0 / 4%);
font-size: .875rem;
/* padding: 10px 0px 18px 0px !important; */
background-color: #ffffff;
}
.navbar-top-submenu {
background: white !important;
}
/* .navbar-light .navbar-toggler-icon {
    background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%280, 0, 0, 0.55%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e) !important;
} */
.plat-right-menu {
    font-size: 15px;
    color: #1b5f9d;
}
a.navbar-top-submenu.dropdown-item.active, a.navbar-top-submenu.dropdown-item:active {
    color: #16181b  !important;
    background-color: #fff  !important;
    padding: 0.1rem 1rem !important;
}
.menu-icon-with-bg {
    width: 30px;
    height: 30px;
    color: #1b5f9d;
    background: #ecf4ff;
    padding: 2px;
}
.menu-icon-without-bg{
    width: 30px;
    height: 30px;
    color: #848484;
    background: transparent;
    padding: 2px;
    font-size: 30px;
}
.menu-admin-panel {
    background: #1b5f9d !important;
    border: none !important;
    color: #fff !important;
    width: 84px !important;
    padding: 0px !important;
    height: 28px !important;
    margin-top: 4px !important;
    border-radius: 6px !important;
    margin-left: 3px !important;
}
.plat-navbar-brand {
    padding: 0px !important;
    margin: 0px !important;
}
.menu-admin-panel:hover {
    background: #1b5f9d !important;
    border: none !important;
    color: #fff !important;
    width: 84px !important;
    padding: 0px !important;
    height: 28px !important;
    margin-top: 4px !important;
}
.menu-admin-panel:active,.menu-admin-panel.active {
    background: #1b5f9d !important;
    border: none !important;
    color: #fff !important;
    width: 84px !important;
    padding: 0px !important;
    height: 28px !important;
    margin-top: 4px !important;
}
.plat-navbar-top-menu a.nav-link {
    padding: 3px !important;
}
@media (max-width: 768px)  {
    /* .dropdown-menu {
        right: auto;
        left: 0;
    } */
}