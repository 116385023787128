.signInDiv {
  width: 75%;
  height: 75%;
  align-items: center !important;
}

.signInHeader {
 color: #1b5f9d;
}

.signInButton {
  padding: 0.5rem;
  border-radius: 10px;
  background-color: #1b5f9d;
  border: 1px solid  #1b5f9d;
  color: white;
  width: 8rem;
}